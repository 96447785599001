<template>
  <d-view title="Dashboard">
    <template v-slot:leftpanel>
      <v-container grid-list-lg pa-0>
        <v-layout row wrap>
          <v-flex xs12>
            <installations
              title="Installations"
              data-cy="installations-widget"
            />
          </v-flex>
          <v-flex xs12 v-if="showSimulation">
            <Simulation :activeSiteId="activeSiteId" />
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-slot:map>
      <main-map
        v-if="activeSite"
        :detections="detections"
        :isCameraDetectingTrack="$_isCameraDetectingTrack"
        data-cy="map"
        :anchorable="true"
      />
    </template>
  </d-view>
</template>
<script>
const MainMap = () => import('@/components/Map/MainMap')
const Installations = () => import('@/components/Widgets/Installations')
import { DetectionsMixin } from '@/components/Mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import Trackers from '@/components/Widgets/Trackers'
import Simulation from '@/components/Widgets/Simulation'

export default {
  name: 'DashboardPage',
  components: {
    MainMap,
    Installations,
    Simulation
  },
  mixins: [DetectionsMixin],
  data: () => ({
    radarsDetections: {},
    rfDetections: {},
    dsxDetections: {},
    rfIntersections: {}
  }),
  beforeDestroy() {
    // opt-out of new fusion detections
    this.disableNewFusionDetections()
    // make sure to clear timeout
    this.$_clearAllDetections()
    this.$bus.$off('SOCKET/SOCKET_RADAR_PERF_STATS_CREATE')
    this.$bus.$off('SOCKET/RF_DETECTIONS_CREATE')
    this.$bus.$off('SOCKET/DISCOVAIR_DETECTIONS_CREATE')
    this.$bus.$off('SOCKET/RADAR_DETECTIONS_CREATE')
    this.$bus.$off('SOCKET/RADAR_RF_DETECTIONS_CREATE')
    this.$bus.$off('SOCKET/RF_INTERSECTIONS_CREATE')
    this.$bus.$off('SOCKET/SITE_WARNINGS_CREATE')
    this.$bus.$off('SOCKET/CAMERA_DETECTIONS_CREATE')
    this.$bus.$off('DOM/RADAR_DETECTION_DELETE')
    this.$bus.$off('DOM/RF_DETECTION_DELETE')
    this.$bus.$off('DOM/DISCOVAIR_DETECTION_DELETE')
    this.$bus.$off('DOM/CHANGE_SITE')
    this.$bus.$off('DOM/RF_INTERSECTION_DELETE')
    this.$emitter.off('clearRfSensorDetections', this.$_clearRfSensorDetection)
  },
  mounted() {
    // opt-in to new fusion detections
    this.enableNewFusionDetections()
    this.pushToAddSiteIfNoSite()

    this.$bus.$on('SOCKET/RF_DETECTIONS_CREATE', ({ message }) => {
      message.data.forEach(d => {
        //check if for dsx sensor or rf sensor
        const dsxSensor = this.dsxSensorById(d.rf_sensor_id)
        if (!dsxSensor) return this.$_updateRFDetections(d)
        this.$_updateDsxDetections(d)
      })
    })
    this.$bus.$on('SOCKET/DISCOVAIR_DETECTIONS_CREATE', ({ message }) => {
      message.data.forEach(d => {
        this.$_updateDiscovairDetections(d)
      })
    })
    this.$bus.$on('SOCKET/RADAR_DETECTIONS_CREATE', ({ message }) =>
      message.data.forEach(d => {
        const { max_track_age, track_tail_length } =
          this.radarsSet[d.radar_id] || {}
        this.$_updateRadarDetections({
          ...d,
          level: 0,
          max_track_age,
          track_tail_length
        })
      })
    )
    this.$bus.$on('SOCKET/RADAR_RF_DETECTIONS_CREATE', ({ message }) =>
      message.data.forEach(d => this.$_updateRadarRfDetections(d))
    )
    this.$bus.$on('SOCKET/CAMERA_DETECTIONS_CREATE', ({ message }) =>
      message.data.forEach(d => {
        this.$_updateCameraDetections(d)
      })
    )
    this.$bus.$on('SOCKET/RF_INTERSECTIONS_CREATE', async ({ message }) =>
      message.data.forEach(d => this.$_updateRFIntersections(d))
    )
    this.$bus.$on('SOCKET/REAL_DRONE_CREATE', async ({ message }) => {
      this.$_updateRealDroneDetections(message.data)
    })
    this.$bus.$on('SOCKET/SIMULATION_CREATE', async ({ message }) => {
      this.$_updateSimulationDetections(message.data)
    })
    this.$bus.$on('DOM/RADAR_DETECTION_DELETE', detection => {
      if (this.radarsDetections[detection.target_id])
        delete this.radarsDetections[detection.target_id]
    })
    this.$bus.$on('DOM/RF_DETECTION_DELETE', detection => {
      const { correlation_key, rf_sensor_id } = detection
      const key = `${correlation_key}:${rf_sensor_id}`
      if (this.rfDetections[key]) delete this.rfDetections[key]
    })
    this.$bus.$on('DOM/DISCOVAIR_DETECTION_DELETE', detection => {
      const { candidate_identifier, discovair_sensor_id } = detection
      const key = `${candidate_identifier}:${discovair_sensor_id}`
      if (this.discovairDetections[key]) delete this.discovairDetections[key]
    })
    this.$bus.$on('CLEAR_DETECTIONS', () => this.$_clearAllDetections())

    this.$bus.$on('DOM/RF_INTERSECTION_DELETE', detection => {
      let id = `${detection.correlation_key}`
      let id1 = `${detection.correlation_key}:${detection.rf_sensor1_id}`
      let id2 = `${detection.correlation_key}:${detection.rf_sensor2_id}`
      if (this.rfIntersections[id]) delete this.rfIntersections[id]
      if (this.rfDetections[id1]) delete this.rfDetections[id1]
      if (this.rfDetections[id2]) delete this.rfDetections[id2]
    })

    this.$emitter.on('clearRfSensorDetections', this.$_clearRfSensorDetection)
    if (this.deferredAlert) {
      setTimeout(() => {
        this.$emitter.emit('displayDeferredAlert')
      }, 500)
    }
  },
  methods: {
    ...mapActions('cameras', ['CAMERA_TRACK_FUSION']),
    ...mapActions('sensors', ['selectDetection']),
    ...mapActions('detection', {
      addDetection: 'addDetection',
      selectFusionDetection: 'selectDetection',
      enableNewFusionDetections: 'enableNewFusionDetections',
      disableNewFusionDetections: 'disableNewFusionDetections'
    }),
    ...mapActions('selection', ['setActiveCamera']),
    pushToAddSiteIfNoSite() {
      if (this.sitesLength === 0) {
        this.$router.push({ name: 'sites.new' })
      }
    },
  },
  watch: {
    status() {
      this.pushToAddSiteIfNoSite()
    }
  },
  computed: {
    ...mapState(['status']),
    ...mapGetters('sites', ['activeSite', 'sitesLength']),
    ...mapState('sites', ['activeSiteId']),
    ...mapState('trackers', ['trackers']),
    ...mapGetters('sensors', [
      'cannonsList',
      'discovair_sensorById',
      'radarsSet'
    ]),
    ...mapGetters('rf_sensors', ['dsxSensorById', 'dsxJammers']),
    ...mapState('cameras', ['camerasSet']),
    ...mapGetters('system', ['systemSetting', 'simulation_enabled']),
    ...mapState('notifications', ['deferredAlert']),
    ...mapState('detection', ['fusionDetections']),
    hasTrackers() {
      return this.trackers && this.trackers.length > 0
    },
    detections() {
      const detections = {
        dsx: this.dsxDetections,
        radars: this.radarsDetections,
        rf: this.rfDetections,
        rfIntersections: this.rfIntersections,
        discovair: this.discovairDetections,
        camera: this.cameraDetections,
        realDroneDetections: this.realDroneDetections,
        simulationDetections: this.simulationDetections
      }
      this.$emitter.emit('detections', detections)
      return detections
    },
    panelHeight() {
      return (this.$refs.infoPanel && this.$refs.infoPanel.clientHeight) || 800
    },
    showSimulation() {
      return this.simulation_enabled
    }
  }
}
</script>

<style>
.mapview {
  position: absolute;
  left: 315px;
  right: 0px;
  margin: 15px;
  top: 0px; /* Header Height */
  bottom: 0px;
}
.panelview {
  position: absolute;
  top: 15px;
  bottom: 0px;
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
