



































import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: "Simulation",
  props: {
    activeSiteId: null,
  },
  computed: {
    ...mapGetters('sites', ['error', 'simulationRunning', 'simulationStartLoading']),
  },
  methods: {
    ...mapActions("sites", { startSimulation: "START_SIMULATION" }),
    ...mapMutations("sites", { setError: "SET_ERROR" }),
    startSim(): void {
      this.setError(null)
      this.startSimulation(this.activeSiteId)
    }
  },
  mounted() {
    this.setError(null)
  }
}
